// import logo from './logo.svg';
import "./index.css";
// import "antd/dist/antd.css";
import { useState } from "react";
// import ReactDOM from 'react-dom';
import { Col, InputNumber, Row, Slider } from "antd";
// import { EnvironmentFilled } from "@ant-design/icons";
import { Venn } from "@ant-design/plots";
import * as beeper from "browser-beep";

const IntegerStep = ({ value, setValue, category }) => {
  const onChange = (newValue) => {
    setValue(newValue);
    // if (newValue % 50 === 0) beeper({ frequency: newValue * 5 })();
    if (newValue === 255) beeper({ frequency: 1000 })(3);
    else if (newValue === 0) beeper({ frequency: 200 })(2);
  };

  return (
    <>
      <Slider
        min={0}
        max={255}
        onChange={onChange}
        value={typeof value === "number" ? value : 0}
        vertical
        style={{
          height: 300,
          paddingLeft: "25%",
        }}
        trackStyle={{ backgroundColor: category }}
        handleStyle={{ borderColor: category }}
      />
      <br />
      <InputNumber
        min={0}
        max={255}
        value={value}
        onChange={onChange}
        addonBefore={`${category[0].toUpperCase()}`}
        // addonAfter={<EnvironmentFilled style={{ color: category }} />}
        size="large"
        style={{ width: 120 }}
      />
    </>
  );
};

const ColorWheel = ({ redValue, greenValue, blueValue }) => {
  const config = {
    data: [
      {
        sets: ["红色"],
        size: 20,
        label: "红色",
      },
      {
        sets: ["绿色"],
        size: 20,
        label: "B",
      },
      {
        sets: ["蓝色"],
        size: 20,
        label: "C",
      },
      {
        sets: ["红色", "绿色"],
        size: 10,
        label: "A&B",
      },
      {
        sets: ["红色", "蓝色"],
        size: 10,
        label: "A&C",
      },
      {
        sets: ["绿色", "蓝色"],
        size: 10,
        label: "B&C",
      },
      {
        sets: ["红色", "绿色", "蓝色"],
        size: 5,
      },
    ],
    color: [
      `rgba(${redValue}, 0, 0, 1)`,
      `rgba(0, ${greenValue}, 0, 1)`,
      `rgba(0, 0, ${blueValue}, 1)`,
    ],
    blendMode: "screen",
    setsField: "sets",
    sizeField: "size",
    pointStyle: {
      fillOpacity: 1,
    },
    padding: [0, 30],
    width: 700,
    height: 700,
    state: {
      active: {
        style: {
          fillOpacity: 1,
          stroke: "white",
          lineWidth: 0,
        },
      },
      selected: {
        style: {
          stroke: "white",
          lineWidth: 0,
        },
      },
    },
    interactions: [
      {
        type: "venn-element-active",
        enable: true,
      },
      {
        type: "venn-element-selected",
        enable: true,
      },
    ],
    // legend: {
    //   position: "bottom",
    //   // itemWidth: 80,
    //   // offsetX: 10,
    //   // offsetY: 100,
    // },
    legend: false,
    label: false,
    tooltip: false
  };
  return <Venn {...config} />;
};

function App() {
  const [redValue, setRedValue] = useState(0);
  const [greenValue, setGreenValue] = useState(0);
  const [blueValue, setBlueValue] = useState(0);
  return (
    <Row>
      <Col span={15}>
        <ColorWheel
          redValue={redValue}
          greenValue={greenValue}
          blueValue={blueValue}
        />
      </Col>
      <Col span={3} style={{ marginTop: 100 }}>
        <IntegerStep value={redValue} setValue={setRedValue} category="red" />
      </Col>
      <Col span={3} style={{ marginTop: 100 }}>
        <IntegerStep
          value={greenValue}
          setValue={setGreenValue}
          category="green"
        />
      </Col>
      <Col span={3} style={{ marginTop: 100 }}>
        <IntegerStep
          value={blueValue}
          setValue={setBlueValue}
          category="blue"
        />
      </Col>
    </Row>
  );
}

export default App;
